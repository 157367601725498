import { Clipboard } from '@angular/cdk/clipboard';
import { Dialog } from '@angular/cdk/dialog';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { HeroEntity, ItemEntity } from '@generated/gql';
import { changeIPFSDomain, getActualUri } from '@helpers/data-helper';
import { AttributesModel, CoreAttributesType } from '@models/attributes.model';
import { ItemEntityExtendedType } from '@models/item-entity-extended.type';
import { StatsType } from '@models/stats.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DestroyService } from '@services/destroy.service';
import { SubgraphService } from '@services/graph/subgraph.service';
import { Mediator } from '@services/mediator.service';
import { StatControllerService } from '@services/onchain/stat-controller.service';
import { SoundService } from '@services/sound.service';
import { LoadingActions } from '@shared/actions/loading.actions';
import { ButtonClickDirective } from '@shared/button-click/button-click.directive';
import { AugmentationLevelComponent } from '@shared/components/augmentation-level/augmentation-level.component';
import { BalanceComponent } from '@shared/components/balance/balance.component';
import { BarComponent } from '@shared/components/bar/bar.component';
import { ForgeDialogComponent } from '@shared/components/forge-dialog/forge-dialog.component';
import { HeroStatItemInputInterface } from '@shared/components/hero-stat-item/hero-stat-item-input-interface';
import { HeroStatItemComponent } from '@shared/components/hero-stat-item/hero-stat-item.component';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';
import { ItemIdComponent } from '@shared/components/item-id/item-id.component';
import { FilterNullPipe } from '@shared/components/pipes/filter-null/filter-null.pipe';
import { RepairDialogComponent } from '@shared/components/repair-dialog/repair-dialog.component';
import { ScratchComponent } from '@shared/components/scratch/scratch.component';
import { SkillAreaComponent } from '@shared/components/skill-area/skill-area.component';
import { TransferDialogComponent } from '@shared/components/transfer-dialog/transfer-dialog.component';
import {
  ATTRIBUTE_TO_DESCRIPTION,
  ATTRIBUTE_TO_ICON,
  ATTRIBUTE_TO_NAME,
  ATTRIBUTES,
  MAGIC_ATTACK_ICON,
  MAGIC_ATTACK_NAME,
} from '@shared/constants/attributes.constant';
import { ITEM_DESCRIPTIONS } from '@shared/constants/descriptions/item-descriptions.constant';
import { getItemNameBySymbol } from '@shared/constants/game.constant';
import { HERO_CORE_STATS_META } from '@shared/constants/hero-levels.constant';
import {
  getBackgroundColorName,
  getTextColorName,
  getTextShadowColorName,
  ITEM_CLASS,
  ITEM_RARITY_TO_NAME,
  ITEM_SLOT_BACKGROUND,
  ITEM_SLOT_NAMES,
  ITEM_TYPE,
  ITEM_TYPE_ICONS,
  ITEM_TYPE_IS_COMMON,
  ITEM_TYPE_NAMES,
} from '@shared/constants/items.constant';
import { MAIN_ROUTES } from '@shared/constants/routes.constant';
import { ItemSlotBgName } from '@shared/types/item-slot-bg-name.type';
import { ItemSlotSize } from '@shared/types/item-slot-size.type';
import { getValueByKey } from '@shared/utils';
import { formatUnits } from 'ethers';
import { NGXLogger } from 'ngx-logger';
import { filter } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
  selector: 'app-item-description',
  standalone: true,
  templateUrl: './item-description.component.html',
  styleUrls: ['./item-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex-column g-scroll-y',
  },
  imports: [
    NgClass,
    ScratchComponent,
    TranslateModule,
    AugmentationLevelComponent,
    ItemIdComponent,
    HeroStatItemComponent,
    SkillAreaComponent,
    FilterNullPipe,
    ButtonClickDirective,
    BarComponent,
    BalanceComponent,
  ],
})
export class ItemDescriptionComponent implements OnInit {
  @Input() chainId?: number;
  @Input() item: ItemEntityExtendedType;
  @Input() slot?: number;
  @Input() hero?: HeroEntity;
  @Input() isShowButtons?: boolean;
  @Input() isApplyItem?: boolean;
  @Input() applyItemLabel?: string;
  @Input() isHideDurability?: boolean;
  @Input() isCanUse?: boolean;
  @Input() isShowClose: boolean = true;

  @Output() takeOff: EventEmitter<{ item: ItemEntity; hero: HeroEntity; equippedSlot: number }> = new EventEmitter();
  @Output() use: EventEmitter<{ useItem: ItemEntity }> = new EventEmitter();
  @Output() closeDialog: EventEmitter<{ page?: string | null; isApply?: boolean | null }> = new EventEmitter();
  @Output() itemRepair: EventEmitter<void> = new EventEmitter();
  @Output() itemForge: EventEmitter<void> = new EventEmitter();

  itemSlotName: string | undefined = '';
  itemsTypeName: string | undefined = '';
  itemRarityName: string | undefined = '';
  itemsTypeIcon: string | undefined = '';

  itemAttributes: AttributesModel;
  // itemAttributesNegative: AttributesModel;

  skillBuffCasterAttributes?: AttributesModel;
  // skillDebuffCasterAttributes?: AttributesModel;
  // skillDebuffTargetAttributes?: AttributesModel;
  skillBuffTargetAttributes?: AttributesModel;
  skillManaConsumption = 0;

  consumableAttributes?: AttributesModel;
  // consumableDebuffAttributes?: AttributesModel;
  consumableStats?: StatsType;

  isCommonItem = false;

  requiredAttributes?: CoreAttributesType;

  headerBackground = '';
  imageUrl = '';

  heroAttributes: AttributesModel;
  heroCoreStats: HeroStatItemInputInterface[] = [];
  slotSize: ItemSlotSize = 'huge';
  backgroundClass? = '';
  description = '';
  name = '';
  isUsed = false;
  durabilityPercentage: number;
  itemDurability: string = '';
  lastPrices: number[] = [];
  avgPrice = 0;
  isNotEnoughRequiredAttributes = false;

  ATTRIBUTE_TO_ICON = ATTRIBUTE_TO_ICON;
  ATTRIBUTE_TO_NAME = ATTRIBUTE_TO_NAME;
  ATTRIBUTE_TO_DESCRIPTION = ATTRIBUTE_TO_DESCRIPTION;
  protected readonly MAGIC_ATTACK_NAME = MAGIC_ATTACK_NAME;
  protected readonly MAGIC_ATTACK_ICON = MAGIC_ATTACK_ICON;
  ATTRIBUTES = ATTRIBUTES;

  constructor(
    private statControllerService: StatControllerService,
    private subgraphService: SubgraphService,
    private changeDetectorRef: ChangeDetectorRef,
    private logger: NGXLogger,
    private dialog: Dialog,
    private destroy$: DestroyService,
    private translateService: TranslateService,
    private router: Router,
    private clipboard: Clipboard,
    private mediator: Mediator,
    private soundService: SoundService,
  ) {}

  onShare() {
    this.clipboard.copy(`${window.location.origin}/item?itemAdr=${this.item.meta.id}&itemId=${this.item.itemId}`);

    this.dialog.open(InfoDialogComponent, {
      panelClass: 'app-overlay-pane',
      data: {
        infoTitle: 'Share',
        infoDesc: 'Link to item is copied in your clipboard and now you can share it with someone',
      },
    });
  }

  onTransfer() {
    this.dialog.open(TransferDialogComponent, {
      panelClass: 'app-overlay-pane',
      data: {
        item: this.item,
      },
    });
  }

  ngOnInit(): void {
    this.router.navigate([], {
      queryParams: {
        modal: 'modal-item',
      },
      queryParamsHandling: 'merge',
    });

    this.imageUrl = getActualUri(this.item);
    this.backgroundClass = this.getBackgroundClass();

    // this.router.navigate([], {
    //   queryParams: {
    //     itemAdr: this.item.meta.id,
    //     itemId: this.item.itemId,
    //   },
    //   queryParamsHandling: 'merge',
    // });

    // this.dialogRef.closed.pipe(takeUntil(this.destroy$)).subscribe(() => {
    //   this.router.navigate([], {
    //     queryParams: {
    //       itemAdr: null,
    //       itemId: null,
    //     },
    //     queryParamsHandling: 'merge',
    //   });
    // });

    this.headerBackground = `url(assets/images/ui/paint/for-item-tittles/${getBackgroundColorName(this.item)}.png)`;

    this.slotDurability();

    this.name = getItemNameBySymbol(this.item.meta.name);
    this.description =
      (this.item.meta.symbol &&
        ITEM_DESCRIPTIONS.get(this.item.meta.symbol.replace('SACRA_', '').replace('_ITEM', ''))) ??
      'Nobody knows what is it';

    this.heroAttributes = AttributesModel.fromStringArray(this.hero?.attributes ?? []);

    this.getFloorPrice();

    this.itemSlotName = ITEM_SLOT_NAMES.get(this.slot ?? 0);
    this.itemsTypeName = ITEM_TYPE_NAMES.get(this.item.meta.itemType);
    this.itemsTypeIcon = ITEM_TYPE_ICONS.get(this.item.meta.itemType);

    this.isCommonItem = ITEM_TYPE_IS_COMMON.get(this.item.meta.itemType) ?? false;

    if (this.isCommonItem) {
      this.itemRarityName = ITEM_RARITY_TO_NAME.get(this.item.rarity);
    }

    if (
      this.item.meta.requirements.strength !== 0 ||
      this.item.meta.requirements.dexterity !== 0 ||
      this.item.meta.requirements.energy !== 0 ||
      this.item.meta.requirements.vitality !== 0
    ) {
      this.requiredAttributes = this.item.meta.requirements;

      this.heroCoreStats = HERO_CORE_STATS_META.map((stat): HeroStatItemInputInterface => {
        const statValue = Number(getValueByKey<AttributesModel>(this.requiredAttributes, stat.id));
        const heroAttrValue = Number(this.hero?.core[stat.id]);

        let text = statValue > 0 ? `${statValue} (${heroAttrValue})` : 0;

        if (heroAttrValue < statValue) {
          text = `${statValue} <span class="color-negative-value">(${heroAttrValue})</span>`;

          this.isNotEnoughRequiredAttributes = true;
        }

        return {
          ...stat,
          text,
          attributeIndex: stat.index ?? 0,
        };
      }).filter(i => i.text !== 0);
    }

    this.itemAttributes = AttributesModel.fromStringArray(this.item.attributes);

    if (this.item.buffInfo) {
      this.skillBuffCasterAttributes = AttributesModel.fromStringArray(this.item.buffInfo.casterAttributes);
      this.skillBuffTargetAttributes = AttributesModel.fromStringArray(this.item.buffInfo.targetAttributes);
      this.skillManaConsumption = this.item.buffInfo.manaConsumption;
    }

    if (this.item.consumableInfo) {
      this.consumableAttributes = AttributesModel.fromStringArray(this.item.consumableInfo.attributes);
      this.consumableStats = this.item.consumableInfo.buffStats;

      if (this.chainId && this.hero) {
        this.statControllerService
          .isConsumableUsed$(this.chainId ?? 0, this.hero?.meta.id ?? '', this.hero?.heroId ?? 0, this.item.meta.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe(isUsed => {
            this.isUsed = isUsed;
            this.changeDetectorRef.markForCheck();
          });
      }
    }
    this.logger.trace('item desc inited', this.item);
    this.changeDetectorRef.detectChanges();

    this.mediator.dispatch(new LoadingActions.PageLoaded(MAIN_ROUTES.ITEM));
  }

  getFloorPrice() {
    this.avgPrice = 0;
    this.subgraphService
      .pawnshopPositionFloor$(this.item.meta.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(floor => {
        this.logger.trace('floor price', floor, this.item);
        this.avgPrice = +floor;
        this.changeDetectorRef.detectChanges();
      });
  }

  isFreeHero() {
    return this.hero?.meta?.feeToken?.amount === '0';
  }

  isItemOwner() {
    return !!this.hero && this.hero?.owner?.id?.toLowerCase() === this.item?.user?.id?.toLowerCase();
  }

  onTakeOff() {
    if (this.hero && this.slot) {
      this.takeOff.emit({
        item: this.item,
        hero: this.hero,
        equippedSlot: this.slot,
      });
    }
  }

  onUse(sound: string | null = null) {
    if (sound) {
      this.soundService.play({ key: sound });
    }

    this.use.emit({ useItem: this.item });
  }

  onClose({ page, isApply }: { page?: string | null; isApply?: boolean | null }): void {
    this.closeDialog.emit({ page, isApply });
  }

  changeURL(url: string) {
    return changeIPFSDomain(url);
  }

  titleColor(item?: ItemEntity): string {
    return item ? getTextColorName(item) : 'inherit';
  }

  titleShadowColor(item?: ItemEntity): string {
    return item ? getTextShadowColorName(item) : 'inherit';
  }

  onShowDurabilityDialog() {
    this.dialog.open(InfoDialogComponent, {
      panelClass: 'app-overlay-pane',
      data: {
        infoTitle: this.translateService.instant('item-description-dialog.durability-title'),
        infoDesc: this.translateService.instant('item-description-dialog.durability-desc'),
      },
    });
  }

  onForge() {
    const dialog = this.dialog.open(ForgeDialogComponent, {
      panelClass: 'app-overlay-pane',
      data: {
        heroToken: this.hero?.meta.id,
        heroId: this.hero?.heroId,
        itemMetaId: this.item.meta.id,
        itemId: this.item.itemId,
      },
    });

    dialog.closed
      .pipe(
        filter(isNeedToUpdate => isNeedToUpdate === true),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.itemForge.emit();
      });
  }

  onRepair() {
    const dialog = this.dialog.open(RepairDialogComponent, {
      panelClass: 'app-overlay-pane',
      data: {
        heroToken: this.hero?.meta.id,
        heroId: this.hero?.heroId,
        itemMetaId: this.item.meta.id,
        itemId: this.item.itemId,
      },
    });

    dialog.closed
      .pipe(
        filter(isNeedToUpdate => isNeedToUpdate === true),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.itemRepair.emit();
      });
  }

  showDurabilityInfo() {
    this.dialog.open(InfoDialogComponent, {
      panelClass: 'app-overlay-pane',
      data: {
        infoTitle: this.translateService.instant('item-description-dialog.durability'),
        infoDesc: this.translateService.instant('item-description-dialog.durability-info'),
      },
    });
  }

  showFragilityInfo() {
    this.dialog.open(InfoDialogComponent, {
      panelClass: 'app-overlay-pane',
      data: {
        infoTitle: this.translateService.instant('item-description-dialog.fragility'),
        infoDesc: this.translateService.instant('item-description-dialog.fragility-info'),
      },
    });
  }

  private getBackgroundClass(): string | undefined {
    if (this.item.meta.itemType === ITEM_TYPE.SKILL) {
      return `app-item-slots-${this.slotSize}_aquamarine-skill${this.item.meta.isAttackItem ? '-attack' : ''}`;
    }

    if (this.item.meta.itemType === ITEM_CLASS.ATTACK) {
      return `app-item-slots-${this.slotSize}_aquamarine`;
    }

    const itemSlotBgName: ItemSlotBgName = getBackgroundColorName(this.item);

    if (this.item.meta.itemType === ITEM_TYPE.SKILL) {
      return `${ITEM_SLOT_BACKGROUND.get(this.slotSize)?.get(itemSlotBgName)}-skill${
        this.item.meta.isAttackItem ? '-attack' : ''
      }`;
    }

    return `${ITEM_SLOT_BACKGROUND.get(this.slotSize)?.get(itemSlotBgName)}`;
  }

  private slotDurability() {
    const curDurability = this.item?.durability ?? 0;
    const totalDurability = this.item?.meta?.durability ?? 0;

    if (totalDurability === 0) {
      this.itemDurability = '';
    } else {
      this.durabilityPercentage = Math.round((curDurability / totalDurability) * 100);

      this.itemDurability =
        this.durabilityPercentage <= 20 || this.durabilityPercentage === 0 ? this.durabilityPercentage + '%' : '';
    }
  }

  protected readonly getActualUri = getActualUri;
  protected readonly formatUnits = formatUnits;
}
