import { DIALOG_DATA, DialogModule, DialogRef } from '@angular/cdk/dialog';
import { CommonModule, DatePipe, LocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { PathPreserveQueryLocationStrategy } from '@services/path-preserve-query-location.strategy';
import { SharedModule } from '@shared/components/shared.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { register } from 'swiper/element/bundle';

import { APP_ROUTES } from './app-routes';
import { GraphQLModule } from './graphql.module';

registerLocaleData(en);
register();

const sentryFeedback = Sentry.feedbackIntegration({
  autoInject: true,
  colorScheme: 'dark',
  showBranding: false,
  showName: true,
  showEmail: true,
  enableScreenshot: true,
  nameLabel: 'Account',
  namePlaceholder: 'Your account address (optional)',
  emailLabel: 'Telegram',
  emailPlaceholder: 'Your Telegram (optional)',
});

sentryFeedback.attachTo(document.querySelector('#btn-sentry-feedback')!, {
  formTitle: 'Report a Bug!',
});

Sentry.init({
  dsn: 'https://24484f58536d8ad47ec623e323c713dc@o4508205821329408.ingest.de.sentry.io/4508205832274000',
  ignoreErrors: [
    'missing r',
    'rejected',
    'error trying to authorize session',
    'No config for',
    'Delegator balance is not enough',
  ],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.replayCanvasIntegration(),
    sentryFeedback,
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/(?:.*\.)?sacra\.game/],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.001, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Sentry.onLoad(() => {
  setTimeout(() => {
    document.querySelector('#sentry-feedback')!.shadowRoot!.querySelector('button')!.style.display = 'none';
  });
});

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      GraphQLModule,
      CommonModule,
      BrowserModule,
      FormsModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
      LoggerModule.forRoot({
        level: environment.STAGING !== 'true' ? NgxLoggerLevel.WARN : NgxLoggerLevel.TRACE,
        serverLogLevel: NgxLoggerLevel.ERROR,
        disableFileDetails: true,
        timestampFormat: 'hh:mm:ss',
      }),
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      DialogModule,
      SharedModule,
      ClickOutsideModule,
      ReactiveFormsModule,
      NgxGoogleAnalyticsModule.forRoot(environment['GOOGLE_ANALYTICS_ID'] || 'G-2QHBHH94PX'),
      NgxGoogleAnalyticsRouterModule,
    ),
    {
      provide: LOCALE_ID,
      useValue: 'en',
    },
    DatePipe,
    { provide: DIALOG_DATA, useValue: {} },
    {
      provide: DialogRef,
      useValue: {
        close: () => {},
      },
    },
    { provide: LocationStrategy, useClass: PathPreserveQueryLocationStrategy },
    // ----------- sentry ----------------
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideRouter(APP_ROUTES),
    // provideRouter(APP_ROUTES, withPreloading(PreloadAllModules)),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
};
